export const shadowFragmentShader: string = `
precision highp float;

    uniform sampler2D penumbraMap;
    uniform sampler2D matcap;
    varying vec2 v_uv;
    varying vec3 v_normal;
    varying vec4 v_lightSpacePosition;
    varying vec3 v_lightDirection;
    varying vec2 v_instanceColor;
    varying vec3 v_viewPosition;

    // adjustable parameters
    uniform vec3 shadowColor;
    uniform float minLightIntensity;
    uniform float maxLightIntensity;
    uniform float shadowIntensity;
    uniform bool circle;

    void main() {
        vec2 st = v_uv;
        vec3 normal = normalize(v_normal * 0.5 + 0.5);
        vec3 lsp = v_lightSpacePosition.xyz / v_lightSpacePosition.w * 0.5 + 0.5;


         vec3 viewDir = normalize( v_viewPosition );
        vec3 matx = normalize( vec3( viewDir.z, 0.0, - viewDir.x ) );
        vec3 maty = cross( viewDir, matx );
        vec2 matcapUV = vec2( dot( matx, normal ), dot( maty, normal ) ) * 0.495 + 0.5;

        vec4 matcapColor = texture2D(matcap, matcapUV);


        float lspShadow = 1. - lsp.z;
        float shadowValue = 0.;
        float bias = 0.004;
        vec4 penumbra = texture2D(penumbraMap, lsp.xy);
        // bool y = distance(v_instanceColor, penumbra.gb) > bias;
        vec2 a = abs(v_instanceColor - penumbra.gb);
        bool y = (a.x + a.y) > bias;
        if (lspShadow < penumbra.x - bias && y) {
            shadowValue = 1.;
        } else if (y) {
            shadowValue = penumbra.a;
        }
        float lambert = max(dot(normal, v_lightDirection), 0.0);
        float lmixed = mix(minLightIntensity, maxLightIntensity, lambert);
        vec3 lambertColor = circle ? vec3(lmixed) : matcapColor.xyz * lmixed;
        float c = step(length(st * 2. - 1.), 0.75);
        gl_FragColor = vec4(mix(lambertColor, min(shadowColor, lambertColor), shadowValue * shadowIntensity), circle ? c : 1.);
        // gl_FragColor = vec4(v_instanceColor.xy, 0.5, 1.);
        // gl_FragColor = vec4(penumbra.gb, 0.5, 1.);
    }
`;
