import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
  Outlet,
  useParams,
} from "react-router-dom";
import { Fabric } from "features/fabric";
import { AppDataProvider } from "../global-state/app-context";
import UserProfile from "features/ui/components/profile/profile";

import { FabricHtml } from "features/ui/html";
import Auth, {
  getAuthenticatedCookie,
  openAuthModal,
} from "features/ui/components/auth/auth";
import { useState, useEffect } from "react";

export interface RouteParams extends Record<string, string> {
  slug: string;
  sectorId: string;
  clusterId: string;
}
const PrivateRoute = ({
  isAuthenticated,
  children,
}: {
  isAuthenticated: boolean;
  children: JSX.Element;
}) => {
  return isAuthenticated ? children : <Navigate to="/auth" />;
};

export const Ui = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    getAuthenticatedCookie() || false
  );

  const FabricWithProvider = () => <Fabric />;

  // useEffect(() => {
  //   if (!isAuthenticated && !window.location.pathname.includes("auth")) {
  //     window.location.pathname = "/auth";
  //   }
  // }, [isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated && window.location.pathname.includes("favorites")) {
      openAuthModal();
    }
  }, [isAuthenticated]);

  const Layout = () => {
    const location = useLocation();
    const { slug, sectorId, clusterId } = useParams<RouteParams>();

    return (
      <AppDataProvider>
        <div id="modal-root"></div>
        <div className="absolute top-0 left-0 w-full h-full">
          <FabricHtml
            slug={slug}
            sectorId={sectorId}
            clusterId={clusterId}
            location={location}
          />
          <div className="absolute top-0 left-0 w-full h-full z-10">
            <Outlet />
          </div>
        </div>
      </AppDataProvider>
    );
  };
  return (
    <Router>
      <Routes>
        <Route
          path="/auth"
          element={<Auth setIsAuthenticated={setIsAuthenticated} />}
        />

        <Route path="/*" element={<Layout />}>
          <Route index element={<Navigate to="/" />} />
          <Route path=":slug" element={<FabricWithProvider />} />
          <Route path=":slug/:sectorId" element={<FabricWithProvider />} />
          <Route
            path=":slug/:sectorId/:clusterId"
            element={<FabricWithProvider />}
          />
          <Route
            path="profile"
            element={
              <PrivateRoute isAuthenticated={isAuthenticated}>
                <UserProfile />
              </PrivateRoute>
            }
          />
          <Route
            path=":slug/favorites"
            element={
              <PrivateRoute isAuthenticated={isAuthenticated}>
                <FabricWithProvider />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </Router>
  );
};

// import {
//   BrowserRouter as Router,
//   Route,
//   Routes,
//   useLocation,
//   Navigate,
//   Outlet,
//   useParams,
// } from "react-router-dom";
// import { Fabric } from "features/fabric";
// import { AppDataProvider } from "../global-state/app-context";
// import UserProfile from "features/ui/components/profile/profile"

// import { FabricHtml } from "features/ui/html";
// import Auth from "features/ui/components/auth/auth";
// import { useState, useEffect } from "react";

// export interface RouteParams extends Record<string, string> {
//   slug: string;
//   sectorId: string;
//   clusterId: string;
// }

// export const getAuthenticatedCookie = () => {
//   const cookieValue = document.cookie.match(/isAuthenticated=([^;]*)/);
//   return cookieValue ? cookieValue[1] === 'true' : null;
// }

// export const Ui = () => {
//   const [isAuthenticated, setIsAuthenticated] = useState(getAuthenticatedCookie() || false);

//   const FabricWithProvider = () => <Fabric />;

//   useEffect(() => {
//     if (!isAuthenticated && !window.location.pathname.includes("auth")) {
//       // window.location.pathname = "/auth";
//     }
//   }, []);

//   const Layout = () => {
//     const location = useLocation();
//     const { slug, sectorId, clusterId } = useParams<RouteParams>();

//     return (
//       <AppDataProvider>
//         <div className="absolute top-0 left-0 w-full h-full">
//           <FabricHtml slug={slug} sectorId={sectorId} clusterId={clusterId} location={location} />
//           <div className="absolute top-0 left-0 w-full h-full z-10">
//             <Outlet />
//           </div>
//         </div>
//       </AppDataProvider>
//     );
//   };
//   return (
//     <Router>
//       <Routes>
//         {!isAuthenticated ? (
//           <Route
//             path="*"
//             element={<Auth setIsAuthenticated={setIsAuthenticated} />}
//           />
//         ) : (
//           <>
//             <Route
//               path="/auth"
//               element={<Auth setIsAuthenticated={setIsAuthenticated} />}
//             />
//             <Route path="/*" element={<Layout />}>
//               <Route index element={<Navigate to="/" />} />
//               <Route path="profile" element={<UserProfile />} />

//               <Route path=":slug" element={<FabricWithProvider />} />
//               <Route path=":slug/:sectorId" element={<FabricWithProvider />} />
//               <Route
//                 path=":slug/:sectorId/:clusterId"
//                 element={<FabricWithProvider />}
//               />
//               <Route path="*" element={<Navigate to="/" />} />
//             </Route>
//           </>
//         )}
//       </Routes>
//     </Router>
//   );
// };
