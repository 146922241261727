import { useDC, useFrame } from "features/draw-call/tsx/canvas";
import { FC, memo, useEffect, useMemo } from "react";
import {
  useCamera,
  useSharedGeometry,
  useSharedShaderProgram,
} from "../scene-context";
import { ElementsBufferGeometry } from "features/draw-call/core/geometry/elements-buffer-geometry";
import { vec3 } from "gl-matrix";
import { ClusterTops } from "../render-tasks/cluster-tops";
import { DistanceEmerge } from "../render-tasks/distance-emerge";
import { FADE_DURATION } from "config";
import { useCluster } from "./sectors";
import { useTexture, useTextureCache } from "features/resource-loader";
import { useAppState } from "features/global-state/app-context";
import { AppStateEmerge } from "../render-tasks/app-state-emerge";

export const ClusterTopsComponent: FC = memo(({}) => {
  const { cluster, sectorIndex, clusterIndex } = useCluster();
  const { gl, rl } = useDC();
  const textureCache = useTextureCache();
  const camera = useCamera();
  const stateManager = useAppState();
  const sharedGeometry = useSharedGeometry(
    "clusterTop"
  ) as ElementsBufferGeometry;
  const loadingProgram = useSharedShaderProgram("loadingClusterThumbnails");
  const mainProgram = useSharedShaderProgram("instancedClusterThumbnails");
  const instancedShadowsProgram = useSharedShaderProgram("instancedShadows");
  const rt = useMemo(
    () =>
      new AppStateEmerge(gl, {
        rl,
        duration: FADE_DURATION,
        stateManager,
        viewMode: "CLUSTER",
        clusterId: clusterIndex,
        sectorId: sectorIndex,
        children: [
          new ClusterTops(gl, {
            sharedGeometry,
            camera,
            cluster,
            loadingProgram,
            mainProgram,
            instancedShadowsProgram,
            textureCache,
            rl,
            sectorIndex,
            clusterIndex,
            stateManager,
          }),
        ],
      }),
    []
  );
  useEffect(() => {}, []);

  useFrame(rl => {
    rt.render();
  });
  return null;
});
