import { useAppState } from "features/global-state/app-context";
import { Caption } from "./caption";
import { useCluster } from "./sectors";
import { memo } from "react";

export const ClusterCaption = memo(() => {
  const { cluster, sectorIndex, clusterIndex } = useCluster();
  const stateManager = useAppState();
  return (
    <Caption
      viewMode="SECTOR"
      handle={{ type: "cluster", id: `${clusterIndex}` }}
      imageURL={stateManager.getImageURL(cluster.image)}
      sectorId={sectorIndex}
      text={cluster.text}
      x={cluster.x}
      y={cluster.y}
    />
  );
});
