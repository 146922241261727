export const VIDEOS = [
  "videos/5sec.mp4",
  "videos/CNBC-stream.mp4",
  "videos/stream-1.mp4",
  "videos/stream-2.mp4",
  "videos/prev/youtube/video_youtube/gjDmb2Awn9U__05_05_1.1.webm",
  "videos/prev/youtube/9ewOSGfVqOM__05_05_1.1.webm",
  "videos/prev/youtube/9pMOSETgGB8__05_05_1.1.webm",
  "videos/prev/youtube/9QxuoeMkX78__05_05_1.1.webm",
  "videos/prev/youtube/3SHRqdMVc8k__05_05_1.1.webm",
  "videos/prev/youtube/2qgbhsS6p34__05_05_1.1.webm",
  "videos/prev/youtube/9WFWV_n04KU__05_05_1.1.webm",
  "videos/prev/youtube/9na_Npb1kLU__05_05_1.1.webm",
  "videos/prev/youtube/video_youtube/Kf5mcnnT_hk__05_05_1.1.webm",
  "videos/prev/youtube/video_youtube/NtG7xnVwQ8c__05_05_1.1.webm",
  "videos/prev/youtube/video_youtube/gfarK2fTGE4__05_05_1.1.webm",
];
export const VIDEOS_COUNT = VIDEOS.length;
