// various constants

import { vec3 } from "gl-matrix";
import { rad } from "math";
import { hextogl } from "utils/hextogl";

export const RICH_PRODUCT_COUNT = mobile() ? 4 : 20;

// camera-related constants
export const CAMERA_FOV = rad(37);
export const CAMERA_NEAR = 0.1;
export const CAMERA_FAR = 1000;
export const CAMERA_2D_THRESHOLD = rad(20);
// cluster config
export const MESH_PADDING = 1;
export const CAMERA_PADDING = 2;
// export const SHADOWMAP_SIZE = 4096;
// shadows
// export const SHADOWMAP_SIZE = 256;
export const SHADOWMAP_SIZE = 256;
export const SVG_SCALE = mobile() ? 0.3 : 0.5;
export const MIN_LIGHT_INTENSITY = 0.76;
export const MAX_LIGHT_INTENSITY = 1;
export const SHADOW_COLOR = hextogl("#d8d8d8");
export const SHADOW_INTENSITY = 1;
export const MATCAP_MAX = 1;

export const GROUND_OFFSET = -0.2;
export const BOX_MARGIN = 0.05;
// export const clusterImagePath = (s: string) => `/images/clusters/${s}.jpg`;
export const clusterImagePath = (category: string, s: string) =>
  `/static-data/${category}/thumbnails/${s}.webp`;
// export const clusterVideoPath = (s: string) =>
//   `/videos/prev/youtube/${s}.webm`;
export const BOX_GROW_TIME = 3000;
export const resourcePath = (s: string) => `/${s}`;

// maximum frametime we can afford
// this number corresponds to about 60fps
export const MAX_FRAMETIME = 16;
// maximum frametime during precalculation phase
export const MAX_PRECALC_FRAMETIME = 16;

// distance constants
export const FADE_DURATION = 250;
export const LO_RES_MAX_DISTANCE = 100;
export const HI_RES_MIN_DISTANCE = 20;
export const CLUSTER_CAPTION_MIN_DISTANCE = 20;
export const CLUSTER_CAPTION_MAX_DISTANCE = 100;

// cluster caption
export const CLUSTER_CAPTION_HEIGHT = 14.5;
export const CLUSTER_CAPTION_SCALE = 16;
export const SECTOR_CAPTION_HEIGHT = 24;
export const SECTOR_CAPTION_SCALE = 30;
export const ACCENT_COLOR = "#826aed";

// promo price
export const PROMO_PRICE_MIN_DISTANCE = 20;
export const PROMO_PRICE_MAX_DISTANCE = 70;
export const PROMO_PRICE_HEIGHT = 12;
export const PROMO_PRICE_SCALE = vec3.fromValues(3, 4, 1);

// camera animator
export const FAVORITES_RADIUS = 20;
export const SCENE_FAR = 450;
export const SCENE_NEAR = 350;
export const CLUSTER_NEAR = 0.5;
export const CLUSTER_FAR = 2;
export const SECTOR_NEAR = 2;
export const SECTOR_FAR = 2;

export const CAMERA_OFFSET = 4;
export const MODE_2D_NEAR = CAMERA_NEAR + CAMERA_OFFSET;
export const MODE_2D_FAR = 30 + CAMERA_OFFSET;
export const MODE_2D_DEFAULT = 3 + CAMERA_OFFSET;
export const MODE_2D_CLUSTER_BORDER = 40 + CAMERA_OFFSET;
export const CLUSTER_DEFAULT_MULTIPLIER = 1;
export const CLUSTER_SECTOR_BORDER_MULTIPLIER = 4.5;
export const SECTOR_DEFAULT_MULTIPLIER = 2;
export const SECTOR_SCENE_BORDER_MULTIPLIER = 2.5;
export const SCENE_DEFAULT = 375;
// export const SCENE_FAR = 280;
export const CAMERA_ANIMATION_SPEED = 2;
export const CAMERA_ANIMATION_ANGULAR_SPEED = 5;

export function mobile(): boolean {
  return window.innerWidth < 768;
}
