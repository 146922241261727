// import { mat4, vec3 } from "gl-matrix";
// import { ClusterComponent } from "./components/cluster";
// import { useEffect, useMemo, useState } from "react";
// import { OrbitCamera } from "features/draw-call/ext/orbit-camera";
// import { GroundScene } from "./components/ground";
// import { AdvertisingBanner } from "./components/advertising-banner";
// import { useDC, useFrame } from "features/draw-call/tsx/canvas";
// import { rad } from "features/draw-call/ext/math";
// import { GroundMeshScene } from "./components/ground-mesh";
// import { AsyncSectorRenderer } from "./components/sectors";
// import { CenterRingComponent } from "./components/center-ring";
// import { AdRingComponent } from "./components/ad-ring";
// import { AdsComponent } from "./components/ads";
// import { ClusterRings } from "./components/cluster-rings";
// import { GeneralBackground } from "./components/general-background";
// import { useSectors } from "features/global-state/app-context";
// import { ClusterTopsComponent } from "./components/cluster-tops";
// import { ClusterCaption } from "./components/cluster-caption";
// import { PromoPrice } from "features/fabric/components/promo-price"
// import { SectorCaptions } from "./components/sector-captions";
// import { ColliderVisualizer } from "./components/collider-visualizer";
// import { SectorHoverComponent } from "./components/sector-hover";
// import { Sliders } from "./components/sliders";

// export const Scene = () => {
//   const { gl, width, height } = useDC();

//   useEffect(() => {
//     gl.enable(gl.BLEND);
//     gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
//   }, []);

//   const sectors = useSectors();

//   return (
//     <>
//       <GeneralBackground />
//       <GroundScene />
//       {/* <AdRingComponent /> */}
//       <Sliders />
//       <AsyncSectorRenderer />
//       <GroundMeshScene />
//       <ClusterRings />
//       <CenterRingComponent />
//       <AdvertisingBanner url={`images/t_mobile_logo.png`} />
//       <SectorCaptions />
//       {/* uncomment collider visualizer to debug raycaster */}
//       {/* <ColliderVisualizer /> */}
//     </>
//   );
// };

import { mat4, vec3 } from "gl-matrix";
import { ClusterComponent } from "./components/cluster";
import { useEffect, useMemo, useState } from "react";
import { OrbitCamera } from "features/draw-call/ext/orbit-camera";
import { GroundScene } from "./components/ground";
import { AdvertisingBanner } from "./components/advertising-banner";
import { useDC, useFrame } from "features/draw-call/tsx/canvas";
import { rad } from "features/draw-call/ext/math";
import { GroundMeshScene } from "./components/ground-mesh";
import {
  ForEachCluster,
  ForEachSector,
  ForEachFavorites,
} from "./components/sectors";
import { CenterRingComponent } from "./components/center-ring";
import { AdRingComponent } from "./components/ad-ring";
// import { AdsComponent } from "./components/ads";
import { ClusterRings } from "./components/cluster-rings";
import { GeneralBackground } from "./components/general-background";
import { useSectors } from "features/global-state/app-context";
import { ClusterTopsComponent } from "./components/cluster-tops";
import { ClusterCaption } from "./components/cluster-caption";
import { PromoPrice } from "features/fabric/components/promo-price";
import { SectorCaptions } from "./components/sector-captions";
import { ColliderVisualizer } from "./components/collider-visualizer";
import { SectorHoverComponent } from "./components/sector-hover";
import { Sliders } from "./components/sliders";
import { RichProductsComponent } from "./components/rich-products";
import { SectorWithGrid } from "./Sector";

export const Scene = () => {
  const { gl, width, height } = useDC();

  useEffect(() => {
    gl.enable(gl.BLEND);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
  }, []);

  const sectors = useSectors();

  return false ? (
    <>
      <ForEachCluster>
        <ClusterComponent />
        <ClusterTopsComponent />
        {/* <PromoPrice /> */}
      </ForEachCluster>
    </>
  ) : (
    <>
      <GeneralBackground />
      <GroundScene />
      <ForEachSector>
        <SectorHoverComponent />
      </ForEachSector>
      <ForEachCluster>
        <ClusterComponent />
        <ClusterTopsComponent />
        {/* <PromoPrice /> */}
      </ForEachCluster>
      <ForEachFavorites>
        <ClusterComponent />
        {window.location.pathname.includes("favorites") && (
          <>
            <ClusterTopsComponent />
            <RichProductsComponent />
          </>
        )}
      </ForEachFavorites>
      <GroundMeshScene />
      <ClusterRings />
      <CenterRingComponent />
      <AdvertisingBanner url={`images/t_mobile_logo.png`} />
      <ForEachCluster>
        <ClusterCaption />
      </ForEachCluster>
      <Sliders />
      <SectorCaptions />
      <ForEachCluster>
        <RichProductsComponent />
      </ForEachCluster>

      <SectorWithGrid />

      {/* uncomment collider visualizer to debug raycaster */}
      {/* <ColliderVisualizer /> */}
    </>
  );
};
