import { createNanoEvents, Emitter } from "nanoevents";
import { useEffect, useState } from "react";
import { Sector, Cluster, SearchDataItem, Favorite } from "./app-context";
import { State } from "swr";
import { Handle, physics, SectorShape } from "features/physics";
import { CLUSTER_CAPTION_HEIGHT } from "config";
import { sectorCoords } from "math/sector-coords";
import { OrbitCamera } from "features/draw-call/ext/orbit-camera";

// const searchParams = new URLSearchParams(window.location.search);
const rawParams = window.location.search.replace("?", "").split("$");
const params = new URLSearchParams(rawParams.join("&"));

const productIndex = params.get("product");
const searchQuery = params.get("search");

export interface StateEvents {
  navigation: (newState: any) => void;
  viewModeChange: (newViewMode: ViewMode) => void;
  sectorsChange: (sectors: any) => void;
  searchDataChange: (searchData: any) => void;
  favoritesChange: (favorites: Favorite[]) => void;
  favoritesError: (error: string) => void;
  clusterId: (clusterId: number | null) => void;
  sectorId: (sectorId: number | string | null) => void;
  productId: (productId: string | null) => void;
}

export const VIEW_MODES = [
  "SCENE",
  "SECTOR",
  "CLUSTER",
  "FAVORITES",
  "SEARCH",
] as const;

export type ViewMode = (typeof VIEW_MODES)[number];

export type LayerMode = "HEAT_MAP" | "PROMO_PRICE"; // [] - empty array = "DEFAULT"
export type layersModes = LayerMode[] | [];

export interface NavigationState {
  productId: string | null;
  viewMode: ViewMode;
  layersModes: layersModes;
  sceneId: string | null;
  sectorId: number | string | null;
  clusterId: number | null;
  coords: { x: number; y: number } | null;

  searchData: SearchDataItem[] | null;
  searchQuery: string | null;
  favorites: Favorite[] | null;
  nomodal: boolean;
}

export class StateManager<T extends NavigationState> {
  state: T;

  sectors: Sector[] = [];
  // searchData: SearchDataItem[] = [];
  emitter: Emitter<StateEvents>;

  constructor(initialState: T) {
    this.state = initialState;
    this.emitter = createNanoEvents<StateEvents>();
    this.updateViewMode();
    this.updatePhysicsWorld();
  }

  setState<K extends keyof T>(key: K, value: T[K]): void {
    this.state = { ...this.state, [key]: value };
    // this.updateURL();
    this.updateViewMode();
    this.updatePhysicsWorld();
    this.emitter.emit("navigation", this.state);
    console.log("state update", key, value);
    this.emitter.emit(key as any, value);
  }

  setFavorites(favorites: Favorite[]): void {
    this.state.favorites = favorites;
    this.updateViewMode();
    this.updatePhysicsWorld();
    this.emitter.emit("navigation", this.state);
    this.emitter.emit("favoritesChange", favorites);
  }

  on<E extends keyof StateEvents>(
    event: E,
    callback: StateEvents[E]
  ): () => void {
    return this.emitter.on(event, callback);
  }

  setSectors(sectors: Sector[]): void {
    this.sectors = sectors;
    this.updatePhysicsWorld();
    this.emitter.emit("sectorsChange", sectors);
  }

  setSearchData(searchData: SearchDataItem[]): void {
    this.state.searchData = searchData;
    this.updateViewMode();
    this.updatePhysicsWorld();
    this.emitter.emit("searchDataChange", searchData);
  }

  getImageURL(imageId: string): string {
    return `/static-data/${this.state.sceneId}/product-images/${imageId}.webp`;
  }
  // TODO: unify with getImageURL
  getProductImageURL(imageId: string): string {
    return `/static-data/${this.state.sceneId}/product-images/${imageId}.webp`;
  }

  private updateViewMode() {
    const old = this.state.viewMode;
    if (
      this.state.sectorId === "favorites" ||
      window.location.pathname.includes("favorites")
    ) {
      this.state.viewMode = "FAVORITES";
    } else {
      if (typeof this.state.clusterId === "number") {
        this.state.viewMode = "CLUSTER";
      } else if (typeof this.state.sectorId === "number") {
        this.state.viewMode = "SECTOR";
      } else {
        this.state.viewMode = "SCENE";
      }
    }
    if (old !== this.state.viewMode) {
      this.emitter.emit("viewModeChange", this.state.viewMode);
    }
  }

  updatePhysicsWorld() {
    physics.clear();
    if (this.state.viewMode === "CLUSTER") {
      const boxes =
        this.sectors[+this.state.sectorId!]?.clusters[this.state.clusterId!]
          ?.ids;
      if (!boxes) return;
      for (const box of boxes) {
        physics.addAABB(
          { position: [box.x, box.h / 2, box.y], scale: [box.w, box.h, box.l] },
          { id: box.id, type: "box" }
        );
      }
    } else if (this.state.viewMode === "SECTOR") {
      const clusters = this.sectors[+this.state.sectorId!]?.clusters;
      if (!clusters) return;
      for (const cluster of clusters) {
        physics.addAABB(
          {
            position: [cluster.x, CLUSTER_CAPTION_HEIGHT / 2, cluster.y],
            scale: [cluster.r * 2, CLUSTER_CAPTION_HEIGHT, cluster.r * 2],
          },
          { id: cluster.c.toString(), type: "cluster" }
        );
      }
    } else if (this.state.viewMode === "SCENE") {
      const sectors = this.sectors;
      for (const sector of sectors) {
        const sectorShape: SectorShape = {
          a: sector.a,
          aw: sector.aw,
          innerRadius: 24,
          outerRadius: 170,
        };

        physics.addSectorShape(sectorShape, {
          id: sector.sector,
          type: "sector",
        });

        // const { center, width, height } = sectorCoords(sector);
        // physics.addAABB(
        //   {
        //     position: [center.x, 0, center.y],
        //     scale: [width, CLUSTER_CAPTION_HEIGHT / 4, height],
        //   },
        //   { id: sector.sector, type: "sector" }
        // );
      }

      //central ring
      physics.addAABB(
        { position: [0, 0, 0], scale: [50, 5, 50] },
        // { id: 'favorites-centr', type: "favorites" }
        { id: "favorites", type: "favorites" }
      );
    } else if (this.state.viewMode === "FAVORITES") {
      if (this.state.favorites) {
        for (const favorite of this.state.favorites) {
          physics.addAABB(
            {
              position: [favorite.x, 1, favorite.y],
              scale: [1, 1, 1],
            },
            {
              id: favorite.asin,
              type: "box",
            }
          );
        }
      }
    }
  }

  private updateURL() {
    const url = new URL(window.location.href);
    if (this.state.productId) {
      url.searchParams.set("product", this.state.productId || "");
    }
    const path = `/${this.state.sceneId || ""}/${this.state.sectorId || ""}/${
      this.state.clusterId || ""
    }`.replace(/\/+$/, "");
    window.history.pushState({}, "", `${path}${url.search}`);
  }
}

export type AppState = StateManager<NavigationState>;

export const globalState = new StateManager<NavigationState>({
  productId: productIndex,
  viewMode: "SCENE",
  layersModes: [],
  sceneId: null,
  sectorId: null,
  clusterId: null,

  searchData: null,
  searchQuery: searchQuery,
  favorites: null,
  nomodal: false,
  coords: null,
});

export const setState = globalState.setState.bind(globalState);
export const on = globalState.on.bind(globalState);
