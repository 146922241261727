import { ACCENT_COLOR, SHADOWMAP_SIZE } from "config";
import { FramebufferTexture } from "features/draw-call/core/framebuffer-texture";
import { Texture } from "features/draw-call/core/texture";
import { useDC } from "features/draw-call/tsx/canvas";
import { useMemo, useRef, useState } from "react";

export const useCreateSharedFBT = () => {
  const { gl } = useDC();
  const shadowMapFBT = useMemo(
    () =>
      new FramebufferTexture(gl, {
        width: SHADOWMAP_SIZE,
        height: SHADOWMAP_SIZE,
        magFilter: gl.NEAREST,
        minFilter: gl.NEAREST,
      }),
    []
  );

  return { shadowMapFBT };
};

export const svgRect = `<svg xmlns="http://www.w3.org/2000/svg" width="1024" height="512">
  <rect stroke="#ffffff" fill="none" stroke-width="18" rx="100" id="svg_1" height="500" width="1000" y="6" x="12"/>
  <rect stroke="none" fill="${ACCENT_COLOR}" stroke-width="0" rx="100" id="svg_1" height="440" width="880" y="36" x="72"/>
  </svg>`;

export const useCreateSharedTextures = () => {
  const { gl } = useDC();
  return useMemo(() => new Map<string, Texture>(), []);
};
