import { useState } from "react";
import { useFetch } from "hooks/fetch";
import { useNavigate, useSearchParams } from "react-router-dom";
import { globalState } from "features/global-state/state-manager";

// const BACKEND_SERVER_URL = process.env.REACT_APP_BACKEND_SERVER_URL;
// const BACKEND_SERVER_URL = 'http://100.123.239.23'

const useSearch = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchData, setSearchData] = useState(null);
  const [searchError, setSearchError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { data: categories } = useFetch("/data/categories.json");

  const removeSearchParam = (param: string) => {
    const params = new URLSearchParams(searchParams);
    params.delete(param);
    setSearchParams(params);
  };

  const handleSearch = async (
    endpoint: string,
    options: RequestInit,
    successfulSearch: () => void
  ) => {
    try {
      setSearchError(null); // Reset error state before search
      const response = await fetch(`${endpoint}`, options);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      // Check if search returned no results
      if (!data || data.length === 0) {
        setSearchError("No results found. Try a different search term.");
        // navigate('/blouses'); // Fallback navigation
        return;
      }

      globalState.setSearchData(data);
      successfulSearch();

      const findCatalog = () => {
        if (!categories || !data || data.length === 0) return;
        const firstSearch = data[0];
        const catalogName = firstSearch.catalog;
        const categoryKey = Object.keys(categories).find(
          key => categories[key].setName === catalogName
        );

        if (categoryKey) {
          //Do not open the product card when searching
          // navigate(`/${categoryKey}/${firstSearch.sector}/${firstSearch.cluster}/?product=${firstSearch.article}`);
          navigate(
            `/${categoryKey}/${firstSearch.sector}/${firstSearch.cluster}?product=${firstSearch.article}&coords=${firstSearch.coords[0]},${firstSearch.coords[1]}&nomodal`
          );
        } else {
          console.error("Category not found for catalog:", catalogName);
          setSearchError("Unable to navigate to search results");
          // navigate('/blouses');
        }
      };

      findCatalog();
    } catch (error) {
      console.error("Search Error:", error);
      setSearchError("An error occurred during search. Please try again.");
      removeSearchParam("search");
      // navigate('/blouses');
    }
  };

  return { searchData, searchError, handleSearch };
};

export default useSearch;
