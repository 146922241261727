import { TexturePixels, createTexture } from "./gl-utils";
import { textureBudget } from "./texture-budget";

// a (relatively) thin wrapper around WebGLTexture
export class Texture {
  width: number;
  height: number;
  texture: WebGLTexture | null = null;
  // only webgl2 support for now
  // it allows to pass HTMLImageElement directly as texture pixels
  gl: WebGL2RenderingContext;
  minFilter: number;
  magFilter: number;
  wrapS: number;
  wrapT: number;
  flip: boolean;
  premultiply: boolean;
  pixels: TexturePixels | null;
  created: boolean = false;
  updated: boolean = false;
  constructor(gl: WebGL2RenderingContext, options?: TextureOptions) {
    this.gl = gl;
    const opts = options || {};
    // @ts-expect-error
    const w = opts.width || opts.pixels?.naturalWidth || opts.pixels?.width;
    // @ts-expect-error
    const h = opts.height || opts.pixels?.naturalHeight || opts.pixels?.height;
    if (!w || !h) {
      throw new Error("Texture: width and height must be specified");
    }
    this.width = w;
    this.height = h;
    this.minFilter = opts.minFilter || gl.LINEAR_MIPMAP_LINEAR;
    this.magFilter = opts.magFilter || gl.LINEAR_MIPMAP_LINEAR;
    this.wrapS = opts.wrapS || gl.CLAMP_TO_EDGE;
    this.wrapT = opts.wrapT || gl.CLAMP_TO_EDGE;
    this.flip = opts.flip || false;
    this.premultiply = opts.premultiply || false;
    this.pixels = opts.pixels || null;

    if (opts.texture) {
      this.texture = opts.texture;
      this.created = true;
    }
  }
  // create gpu texture from pixels
  create() {
    if (this.created) return;
    // console.warn(
    // `Creating a texture with size ${this.width}x${this.height} and ${this.minFilter} filtering`
    // );
    this.texture = createTexture(this.gl, this.width, this.height, {
      pixels: this.pixels,
      minFilter: this.minFilter,
      magFilter: this.magFilter,
      wrapS: this.wrapS,
      wrapT: this.wrapT,
      flip: this.flip,
      premultiply: this.premultiply,
    });
    textureBudget.allocate(this.width, this.height, 4);
    if (this.minFilter == this.gl.LINEAR_MIPMAP_LINEAR) {
      this.gl.generateMipmap(this.gl.TEXTURE_2D);
    }
    this.created = true;
  }
  // update texture from pixels
  update() {
    if (this.updated) return;
    if (!this.created) {
      this.create();
    } else if (this.pixels) {
      const gl = this.gl;
      gl.bindTexture(this.gl.TEXTURE_2D, this.texture);
      if (this.flip) {
        gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, 1);
      } else {
        gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, 0);
      }
      if (this.premultiply) {
        gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, true);
      } else {
        gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, false);
      }
      this.gl.texImage2D(
        this.gl.TEXTURE_2D,
        0,
        this.gl.RGBA,
        this.width,
        this.height,
        0,
        this.gl.RGBA,
        this.gl.UNSIGNED_BYTE,
        // @ts-ignore
        this.pixels
      );
      if (this.minFilter == this.gl.LINEAR_MIPMAP_LINEAR) {
        this.gl.generateMipmap(this.gl.TEXTURE_2D);
      }
    }
    this.updated = true;
  }
  // free all gpu-related resources
  free() {
    this.gl.deleteTexture(this.texture);
    textureBudget.free(this.width, this.height, 4);
    this.created = false;
    this.updated = false;
  }
}

export interface TextureOptions {
  pixels?: TexturePixels | null;
  width?: number;
  height?: number;
  minFilter?: number;
  magFilter?: number;
  wrapS?: number;
  wrapT?: number;
  flip?: boolean;
  premultiply?: boolean;
  texture?: WebGLTexture;
}
