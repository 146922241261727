import { RenderTask } from "features/draw-call/core/render-task/render-task";
import { ShaderProgram } from "features/draw-call/core/shader-program/shader-program";
import { Texture } from "features/draw-call/core/texture";
import { OrbitCamera } from "features/draw-call/ext/orbit-camera";
import { ElementsBufferGeometry } from "features/draw-call/core/geometry/elements-buffer-geometry";
import { hextogl } from "utils/hextogl";
import { useDC, useFrame } from "features/draw-call/tsx/canvas";
import { useTexture } from "features/resource-loader";
import { useControls } from "leva";
import { useMemo, useEffect } from "react";
import { useCamera, useSharedGeometry } from "../scene-context";
import { rotationTranslationScaleMatrix } from "features/draw-call/core/geometry/array-transforms";
import { mat4 } from "gl-matrix";
import { modelMatrixVertexShader300 } from "../shaders/model-matrix-vertex-shader";
import { GBFragmentShader300 } from "../shaders/g-b-fragment-shader";

interface AdRingOptions {
  geometry: ElementsBufferGeometry;
  camera: OrbitCamera;
  // texture0: Texture;
  // texture1: Texture;
}

export class AdRingRenderer extends RenderTask {
  camera: OrbitCamera;
  brightColor: Float32Array;
  darkColor: Float32Array;
  backgroundColor: Float32Array;
  modelMatrix: mat4;
  time: number;
  speed_g_b: number;
  scale: number;
  lightView: mat4;
  cellSize: number;
  lineWidth: number;
  // textures: { texture0: Texture; texture1: Texture };

  constructor(gl: WebGL2RenderingContext, options: AdRingOptions) {
    super(gl);
    this.camera = options.camera;
    this.brightColor = hextogl("#D6D6D6");
    this.darkColor = hextogl("#636365");
    this.backgroundColor = hextogl("#2C2B2F");
    this.time = 0;
    this.speed_g_b = 1;
    this.scale = 1;
    this.cellSize = 5.0264; //12
    this.lineWidth = 0.06;

    this.shaderPrograms.main = new ShaderProgram(gl, {
      vertexShader: modelMatrixVertexShader300,
      fragmentShader: GBFragmentShader300,
    });
    this.geometries.main = options.geometry;
    this.modelMatrix = rotationTranslationScaleMatrix(
      mat4.create(),
      [0, Math.PI, 0],
      [0, -30, 0],
      [900, 500, 900]
    );

    const lightView = mat4.create();
    mat4.lookAt(lightView, [0, 0, 0], [0, 0, -1], [0, 1, 0]);
    this.lightView = lightView;
    // this.textures = {
    //   texture0: options.texture0,
    //   texture1: options.texture1,
    // };
  }

  render(): void {
    super.render();
    const gl = this.gl;
    const uniforms = {
      projectionMatrix: this.camera.projectionMatrix,
      viewMatrix: this.camera.viewMatrix,
      brightColor: this.brightColor,
      darkColor: this.darkColor,
      backgroundColor: this.backgroundColor,
      cellSize: this.cellSize,
      lineWidth: this.lineWidth,
      speed_g_b: this.speed_g_b,
      modelMatrix: this.modelMatrix,
      time: this.time * 0.0001 * this.speed_g_b,
      lightViewMatrix: this.lightView,
      u_scale: this.scale,
    };

    // Render back faces first
    gl.enable(gl.CULL_FACE);
    gl.cullFace(gl.BACK);
    //gl.cullFace(gl.FRONT);
    // gl.depthMask(false); // Disable depth mask to draw the back faces through the front faces
    gl.depthMask(true);
    // this.geometries.main.drawCall(this.shaderPrograms.main, uniforms);
    // Render front faces
    gl.cullFace(gl.FRONT);
    //gl.cullFace(gl.BACK);
    // gl.depthMask(true); // Enable depth mask to ensure proper blending with the back faces
    gl.depthMask(false);
    this.geometries.main.drawCall(this.shaderPrograms.main, uniforms);
    gl.disable(gl.CULL_FACE);
  }
}

export const GeneralBackground = () => {
  const { gl, rl } = useDC();
  const camera = useCamera();
  const geometry = useSharedGeometry("cylinder") as ElementsBufferGeometry;
  // const texture0 = useTexture("images/honeycomb.png");
  // const texture1 = useTexture("images/honeycomb.png");

  const fabric = useMemo(
    () =>
      new AdRingRenderer(gl, {
        geometry,
        camera,
        // texture0,
        // texture1,
      }),
    [gl, geometry, camera]
  );

  /*const { brightColor, darkColor, backgroundColor, speed_g_b, scale_g_b, lineWidth_g_b } = useControls({
    // color_g_b_0: "#523700",
    // color_g_b_1: "#ffffff",
    brightColor: "#D6D6D6",
    darkColor: "#636365",
    backgroundColor: "#2C2B2F",
    speed_g_b: { min: 0.1, max: 4, value: 1.46 },
    scale_g_b: { min: 0.1, max: 10, value: 2.417 },
    lineWidth_g_b: { min: 0.01, max: 0.1, value: 0.1 },
    // color_g_b_0: "#fffefd", //efdfcf
    // color_g_b_1: "#402b00", //624100
    // speed_g_b: { min: 0.1, max: 4, value: 1.46 },
    // scale_g_b: { min: 0.1, max: 10, value: 2.417 },
    // lineWidth_g_b: { min: 0.01, max: 0.1, value: 0.1 },
  });
  fabric.brightColor = hextogl(brightColor);
  fabric.darkColor = hextogl(darkColor);
  fabric.backgroundColor = hextogl(backgroundColor);
  fabric.speed_g_b = speed_g_b;
  fabric.scale = scale_g_b;
  fabric.lineWidth = lineWidth_g_b;*/
  fabric.brightColor = hextogl("#D6D6D6");
  fabric.darkColor = hextogl("#636365");
  fabric.backgroundColor = hextogl("#2C2B2F");
  fabric.speed_g_b = 1.46;
  fabric.scale = 2.417;
  fabric.lineWidth = 0.1;

  useFrame(rl => {
    fabric.time = rl.time;
    fabric.render();
  });

  return null;
};
