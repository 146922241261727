import { ACCENT_COLOR, SVG_SCALE } from "config";
import {
  RenderTaskOptions,
  RenderTask,
} from "features/draw-call/core/render-task/render-task";
import { mat2, mat4 } from "gl-matrix";
import { RenderLoop } from "features/draw-call/core/render-loop";
import { ShaderProgram } from "features/draw-call/core/shader-program/shader-program";
import { Texture } from "features/draw-call/core/texture";
import { UniformValues } from "features/draw-call/core/types";
import { OrbitCamera } from "features/draw-call/ext/orbit-camera";
import { DynamicTextureLoader } from "features/resource-loader/dynamic-texture-loader";

export const svgRect = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" width="${
  1024 * SVG_SCALE
}" height="${1024 * SVG_SCALE}">
  <rect stroke="#ffffff" fill="none" stroke-width="18" rx="100" id="svg_1" height="1000" width="1000" y="12" x="12"/>
  <rect stroke="none" fill="${ACCENT_COLOR}" stroke-width="0" rx="100" id="svg_1" height="880" width="880" y="72" x="72"/>
  </svg>`;

export const textToSvg = (text: string) => {
  return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 512" width="${
    1024 * SVG_SCALE
  }" height="${512 * SVG_SCALE}">
    <foreignObject x="75" y="0" width="874" height="512">
      <div xmlns="http://www.w3.org/1999/xhtml" style="font-family: Inter, sans-serif; font-weight: semibold; display: flex; text-align: center; align-items: center; justify-content: center; height: 100%; font-size: 128px;">
        <div xmlns="http://www.w3.org/1999/xhtml" style="color: white;">
          ${text}
        </div>
      </div>
    </foreignObject>
  </svg>`;
};

export const imageToBase64 = (image: HTMLImageElement) => {
  // Create a canvas element
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d")!;

  // Set canvas dimensions to match the image
  canvas.width = image.width;
  canvas.height = image.height;

  // Draw the image onto the canvas
  context.drawImage(image, 0, 0, image.width, image.height);

  // Get the data URL (which includes the base64 encoding)
  const base64String = canvas.toDataURL("image/png"); // You can change 'image/png' to 'image/jpeg' if needed

  return base64String;
};

export const imageToSvg = (image: HTMLImageElement) => {
  const base64String = imageToBase64(image);
  return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"width="${
    1024 * SVG_SCALE
  }" height="${1024 * SVG_SCALE}">
  <clipPath id="rounded-corners">
    <rect x="72" y="72" width="880" height="880" rx="100" ry="100" />
  </clipPath>
        <image x="72" y="72" width="880"  height="880" clip-path="url(#rounded-corners)" href="${base64String}" />
    </svg>`;
};

export interface CaptionRenderTaskOptions extends RenderTaskOptions {
  text: string;
  imageURL: string;
  camera: OrbitCamera;
  textureCache: DynamicTextureLoader;
  rl: RenderLoop;
}

export class CaptionRenderTask extends RenderTask {
  text: string;
  imageURL: string;
  textureCache: DynamicTextureLoader;
  backgroundTexture?: Texture;
  textTexture?: Texture;
  loadingTextTexture?: Texture;
  imageTexture?: Texture;
  camera: OrbitCamera;
  rl: RenderLoop;
  rotationMatrix: mat2 = mat2.create();

  constructor(gl: WebGL2RenderingContext, options: CaptionRenderTaskOptions) {
    super(gl, options);
    this.text = options.text;
    this.imageURL = options.imageURL;
    this.textureCache = options.textureCache;
    this.camera = options.camera;
    this.rl = options.rl;
    if (!this.geometries.quad) throw new Error("Quad geometry not provided");
    if (!this.geometries.roundedQuad)
      throw new Error("Rounded quad geometry not provided");
    if (!this.shaderPrograms.caption)
      throw new Error("Caption shader not provided");
  }

  create(): void {
    super.create();
  }

  render(u?: UniformValues): void {
    super.render();
    // Render the caption
    if (!this.created) return;
    if (!this.backgroundTexture && !this.loadingTextTexture) {
      // load textures
      this.textureCache
        .loadSVG("caption-rect", svgRect)
        .then(texture => (this.backgroundTexture = texture));
      this.textureCache
        .loadSVG(`text-loading`, textToSvg("Loading..."))
        .then(texture => (this.loadingTextTexture = texture));
      this.textureCache
        .loadSVG(`text-${this.text}`, textToSvg(this.text))
        .then(texture => (this.textTexture = texture));
      this.textureCache
        /*.loadImage(this.imageURL)
      .then(image =>
        this.textureCache.loadSVG(`image-${this.imageURL}`, imageToSvg(image))
      )*/
        .loadURL(this.imageURL)
        .then(texture => (this.imageTexture = texture));
    }

    if (!this.backgroundTexture) return;
    if (!this.loadingTextTexture) return;
    // if (!this.textTexture) return;
    // if (!this.imageTexture) return;
    const speed = 5;
    mat2.fromRotation(this.rotationMatrix, (-this.rl.time / 1000) * speed);
    const uniforms = {
      ...u,
      viewMatrix: this.camera.viewMatrix,
      projectionMatrix: this.camera.projectionMatrix,
      backgroundTexture: this.backgroundTexture,
      textTexture: this.textTexture || this.loadingTextTexture,
      time: this.rl.time,
      rotationMatrix: this.rotationMatrix,
    };
    if (this.imageTexture) {
      /*if (this.imageTexture.created && u?.animationState === 0) {
        this.imageTexture.free();
      }
      if (
        this.textTexture &&
        this.textTexture.created &&
        u?.animationState === 1
      ) {
        this.textTexture.free();
      }*/
      this.geometries.roundedQuad.drawCall(this.shaderPrograms.caption, {
        ...uniforms,
        imageTexture: this.imageTexture,
      });
    } else {
      this.geometries.quad.drawCall(this.shaderPrograms.loadingSectorCaption, {
        ...uniforms,
        transparency: 0.2,
      });
    }
  }
}
