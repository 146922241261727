import React, { useEffect, useState } from "react";
import { useAppState } from "features/global-state/app-context";
import {
  ProductParent,
  Color,
  Size,
} from "features/ui/components/product/product-card";
import SizeButtons from "./size-buttons";

interface ColorButtonsProps {
  parentProduct: ProductParent | null;
  currentColor: string;
  setCurrentColor: (color: string) => void;
  selectedSize: string;
  setSelectedSize: (size: string) => void;
}

const ColorButtons: React.FC<ColorButtonsProps> = ({
  parentProduct,
  currentColor,
  setCurrentColor,
  selectedSize,
  setSelectedSize,
}) => {
  const [imageError, setImageError] = useState<boolean>(false);
  const [sizes, setSizes] = useState<Size[]>([]);
  const stateManager = useAppState();

  // Пересчитываем размеры при изменении цвета или родительского продукта
  useEffect(() => {
    if (parentProduct) {
      // Получаем все размеры для текущего цвета
      const sizesForColor = parentProduct.sizes.filter(size =>
        parentProduct.colors.some(
          color => color.asin === size.asin && color.color === currentColor
        )
      );

      // Убираем дубликаты по полю size
      const uniqueSizes = sizesForColor.reduce((acc: Size[], current) => {
        const exists = acc.find(item => item.size === current.size);
        if (!exists) {
          acc.push(current);
        }
        return acc;
      }, []);

      setSizes(uniqueSizes);
    }
  }, [currentColor, parentProduct]);

  const uniqueColors = parentProduct
    ? Array.from(new Set(parentProduct.colors.map(color => color.color)))
    : [];

  return (
    <>
      <div className="mt-4">
        <h3 className="text-lg font-semibold pb-2">
          <span className="font-[600]">Select Color:</span> {currentColor}
        </h3>
        <div className="mt-2">
          {parentProduct &&
            uniqueColors.map(color => {
              const colorData = parentProduct.colors.find(
                c => c.color === color
              );
              if (!colorData) return null;

              return (
                <button
                  key={color}
                  onClick={() => {
                    setCurrentColor(color);
                    setSelectedSize("");
                  }}
                  className={`mr-2 border rounded overflow-hidden mb-1 ${
                    currentColor === color ? "accent-border" : "border-gray-300"
                  }`}
                  style={{
                    width: "50px",
                    height: "66px",
                    backgroundColor: imageError ? color : "transparent",
                  }}
                >
                  <img
                    src={stateManager.getProductImageURL(colorData.image_id)}
                    alt={`color ${color}`}
                    className="w-full h-full object-cover"
                    onError={() => setImageError(true)}
                  />
                </button>
              );
            })}
        </div>
      </div>

      <div className="mt-4">
        <h3 className="text-lg font-[600] pb-2">Size</h3>
        <div className="mt-2">
          <SizeButtons
            sizes={sizes}
            selectedSize={selectedSize}
            setSelectedSize={setSelectedSize}
          />
        </div>
      </div>
    </>
  );
};

export default ColorButtons;
