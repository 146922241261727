function formatBytes(bytes: number): string {
  if (bytes === 0) return "0 B";

  const units = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const index = Math.floor(Math.log(bytes) / Math.log(1024)); // Determine unit index
  const value = bytes / Math.pow(1024, index); // Convert bytes to the correct unit

  return `${value.toFixed(2)} ${units[index]}`; // Return formatted string with 2 decimal places
}

export class TextureBudget {
  mem: number = 0;
  count: number = 0;
  warn: boolean = false;
  allocate(width: number, height: number, bytesPerPixel: number) {
    const x = width * height * bytesPerPixel;
    this.mem += x;
    this.count++;
    if (!this.warn) return;
    console.warn(
      `Allocated gpu mem for a ${width}x${height} texture with ${bytesPerPixel} bytes per texel (${formatBytes(
        x
      )})`
    );
    console.warn(
      `Total gpu mem usage by ${this.count} textures: ${formatBytes(this.mem)}`
    );
  }
  free(width: number, height: number, bytesPerPixel: number) {
    const x = width * height * bytesPerPixel;
    this.mem -= x;
    this.count--;
    if (!this.warn) return;
    console.warn(
      `Freed gpu mem for a ${width}x${height} texture with ${bytesPerPixel} bytes per texel (${formatBytes(
        x
      )})`
    );
    console.warn(
      `Total gpu mem usage by ${this.count} textures: ${formatBytes(this.mem)}`
    );
  }
}

export const textureBudget = new TextureBudget();
