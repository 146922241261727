import useSWR from "swr";

export const fetchJSON = (url: string) =>
  fetch(url)
    .then(res => res.json())
    .catch(console.error);

export const fetchBinary = (url: string) =>
  fetch(url)
    .then(res => res.arrayBuffer())
    .catch(console.error);

export const useFetch = (url: string) => useSWR(url, fetchJSON);
export const useBinaryFetch = (url: string) => useSWR(url, fetchBinary);
