export interface DrawableBox {
  l: number;
  w: number;
  h: number;
  x: number;
  y: number;
}

export interface DrawableCluster {
  sector: number;
  cluster: number;
  length: number;
  x: number;
  y: number;
  r: number;
  boxes: DrawableBox[];
}

export function decodeBinary(buffer: ArrayBuffer): DrawableCluster[] {
  const view = new DataView(buffer);
  const clusters: DrawableCluster[] = [];
  let offset = 0;
  while (offset < view.byteLength) {
    const { cluster, offset: newOffset } = decodeBinaryCluster(view, offset);
    clusters.push(cluster);
    offset = newOffset;
  }
  return clusters;
}

export function decodeBinaryCluster(
  view: DataView,
  offset: number = 0
): { cluster: DrawableCluster; offset: number } {
  // Read sector (uint16)
  const sector = view.getUint16(offset, true);
  offset += 2;

  // Read cluster (uint16)
  const cluster = view.getUint16(offset, true);
  offset += 2;

  // Read cluster x, y and r
  const clusterX = view.getFloat32(offset, true);
  offset += 4;
  const clusterY = view.getFloat32(offset, true);
  offset += 4;
  const clusterR = view.getFloat32(offset, true);
  offset += 4;

  // Read length (uint32)
  const length = view.getUint32(offset, true);
  offset += 4;

  // Helper function to read an array of uint8
  function readUint8Array(length: number): number[] {
    const arr: number[] = [];
    for (let i = 0; i < length; i++) {
      arr.push(view.getUint8(offset));
      offset += 1;
    }
    return arr;
  }

  // Helper function to read an array of float32
  function readFloat32Array(length: number): number[] {
    const arr: number[] = [];
    for (let i = 0; i < length; i++) {
      arr.push(view.getFloat32(offset, true));
      offset += 4;
    }
    return arr;
  }

  // Read arrays
  const l = readUint8Array(length);
  const w = readUint8Array(length);
  const h = readFloat32Array(length);
  const x = readFloat32Array(length);
  const y = readFloat32Array(length);

  // convert multiple arrays to a single array of objects
  const boxes = l.map((_, i) => ({
    l: l[i],
    w: w[i],
    h: h[i],
    x: x[i],
    y: y[i],
  }));

  // Return as an object
  return {
    cluster: {
      sector,
      cluster,
      length,
      x: clusterX,
      y: clusterY,
      r: clusterR,
      boxes,
    },
    offset,
  };
}
