import { FC, memo, useEffect, useMemo } from "react";
import {
  useCamera,
  useSharedGeometry,
  useSharedShaderProgram,
  useSharedShaderPrograms,
} from "../scene-context";
import { useDC, useFrame } from "features/draw-call/tsx/canvas";
import { RichProductsRenderTask } from "../render-tasks/rich-products";
import { useCluster } from "./sectors";
import { useAppState } from "features/global-state/app-context";
import { useTextureCache } from "features/resource-loader";
import { useFetch } from "hooks/fetch";
import { SWRResponse } from "swr";

export const RichProductsComponent: FC = memo(() => {
  const roundedQuad = useSharedGeometry("roundedQuad");
  const shaderPrograms = useSharedShaderPrograms([
    "textureRenderer",
    "uvDebug",
  ]);
  const { gl, rl } = useDC();
  const camera = useCamera();
  const clusterContext = useCluster();
  const stateManager = useAppState();
  const textureCache = useTextureCache();
  const productData = useFetch(
    `/static-data/${stateManager.state.sceneId}/clusters/${clusterContext.sectorIndex}_${clusterContext.clusterIndex}.json`
  );
  if (productData.data) {
    clusterContext.cluster.ids = productData.data.ids!;
  }

  const rt = useMemo(
    () =>
      new RichProductsRenderTask(gl, {
        geometries: { roundedQuad },
        shaderPrograms,
        camera,
        clusterContext,
        stateManager,
        textureCache,
        rl,
      }),
    [productData.data]
  );
  useFrame(rl => {
    rt.render();
  });
  return null;
});
