import {
  Cluster,
  useAppState,
  useSectors,
} from "features/global-state/app-context";
import React, { FC, memo, ReactNode } from "react";
import { ClusterComponent } from "./cluster";
import { Caption } from "./caption";
import { ClusterTopsComponent } from "./cluster-tops";
import { sectorCoords } from "math/sector-coords";
import { SECTOR_CAPTION_HEIGHT, SECTOR_CAPTION_SCALE } from "config";

export interface ClusterContext {
  cluster: Cluster;
  sectorIndex: number | string;
  clusterIndex: number;
}

const clusterContext = React.createContext<ClusterContext | null>(null);

export const SectorCaptions: FC<{ children?: ReactNode }> = memo(
  ({ children }) => {
    const sectors = useSectors();
    const stateManager = useAppState();
    return (
      <>
        {sectors.map((sector, i) => {
          const { center } = sectorCoords(sector);
          return (
            <Caption
              viewMode="SCENE"
              handle={{ type: "sector", id: sector.sector }}
              imageURL={stateManager.getImageURL(sector.image)}
              scale={SECTOR_CAPTION_SCALE}
              key={i}
              height={SECTOR_CAPTION_HEIGHT}
              x={center.x}
              y={center.y}
              text={sector.text}
            />
          );
        })}
      </>
    );
  }
);
