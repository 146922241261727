import React, { useState } from "react";
import { useAppState } from "features/global-state/app-context";

interface ProductSliderProps {
  images: string[];
}

export const ProductSlider: React.FC<ProductSliderProps> = ({ images }) => {
  const stateManager = useAppState();
  const [currentSlide, setCurrentSlide] = useState(0);

  // const nextSlide = () => {
  //   setCurrentSlide((prev) => (prev + 1) % images.length);
  // };

  // const prevSlide = () => {
  //   setCurrentSlide((prev) => (prev - 1 + images.length) % images.length);
  // };

  return (
    <div className="relative w-full min-h-[100vw] md:min-h-[23rem] h-[33vh] sm:h-[20vh] md:h-[24vh] lg:h-[30vh] xl:h-[36vh] overflow-hidden flex">
      <div className="flex flex-col items-center justify-center space-y-2 mr-4 p-3 absolute z-10 overflow-y-auto h-full">
        {/* //TODO: open modal window for selected foto */}
        {images.map((imageId, index) => (
          <button
            key={index}
            onClick={() => setCurrentSlide(index)}
            className={`border rounded overflow-hidden ${
              currentSlide === index ? "border-orange-500" : "border-gray-300"
            }`}
            style={{ width: "50px", height: "66px" }}
          >
            <img
              src={stateManager.getProductImageURL(imageId)}
              alt={`Thumbnail ${index + 1}`}
              className="w-full h-full object-cover"
              onLoad={() => {
                console.log("Image loaded!");
              }}
              onError={event => {
                (event.target as HTMLImageElement).src =
                  "https://t3.ftcdn.net/jpg/03/34/83/22/360_F_334832255_IMxvzYRygjd20VlSaIAFZrQWjozQH6BQ.jpg";
              }}
            />
          </button>
        ))}
      </div>
      <div className="relative w-full h-full">
        {images.map((imageId, index) => (
          <img
            key={index}
            src={stateManager.getProductImageURL(imageId)}
            alt={`Slide ${index + 1}`}
            className={`absolute w-full h-full object-cover transition-transform duration-500 ${
              index === currentSlide ? "translate-x-0" : "translate-x-full"
            }`}
            style={{
              transform: `translateX(${(index - currentSlide) * 100}%)`,
            }}
            onLoad={() => {
              console.log("Image loaded!");
            }}
            onError={event => {
              (event.target as HTMLImageElement).src =
                "https://t3.ftcdn.net/jpg/03/34/83/22/360_F_334832255_IMxvzYRygjd20VlSaIAFZrQWjozQH6BQ.jpg";
            }}
          />
        ))}
        {/* <button onClick={prevSlide} className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-2">Prev</button>
            <button onClick={nextSlide} className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-2">Next</button> */}
      </div>
    </div>
  );
};
