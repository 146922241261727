import { GLContext, TexturePixels, createTexture } from "./gl-utils";
import { Texture, TextureOptions } from "./texture";
import { OutputFramebuffer } from "./types";

// provides api for rendering to a texture
export class FramebufferTexture extends Texture implements OutputFramebuffer {
  // framebuffer object
  framebuffer: WebGLFramebuffer | null = null;
  depth: boolean;
  depthTexture: WebGLTexture | null = null;

  constructor(
    gl: WebGL2RenderingContext,
    options?: TextureOptions & { depth?: boolean }
  ) {
    super(gl, options);
    this.depth = typeof options?.depth === "undefined" ? true : options.depth;
    // framebuffer texture creates itself
    this.create();
  }
  create(): void {
    super.create();
    const gl = this.gl;
    this.framebuffer = gl.createFramebuffer()!;
    gl.bindFramebuffer(gl.FRAMEBUFFER, this.framebuffer);
    gl.framebufferTexture2D(
      gl.FRAMEBUFFER,
      gl.COLOR_ATTACHMENT0,
      gl.TEXTURE_2D,
      this.texture,
      0
    );
    if (this.depth) {
      this.depthTexture = createTexture(this.gl, this.width, this.height, {
        minFilter: this.gl.NEAREST,
        magFilter: this.gl.NEAREST,
        format: this.gl.DEPTH_COMPONENT,
        internalFormat: this.gl.DEPTH_COMPONENT16,
        type: this.gl.UNSIGNED_SHORT,
      });
      gl.framebufferTexture2D(
        gl.FRAMEBUFFER,
        gl.DEPTH_ATTACHMENT,
        gl.TEXTURE_2D,
        this.depthTexture,
        0
      );
    }
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
  }
  update(): void {
    if (!this.created) {
      this.create();
    }
    this.updated = true;
  }
  // set new width and height
  resize(width: number, height: number) {
    const gl = this.gl;
    this.width = width;
    this.height = height;
    // set created to false or else the update won't trigger
    this.created = false;
    this.update();
  }
  // free everyting but texture
  // useful when writing to the framebuffer is done for good, but reading is still required
  freeFramebuffer() {
    const gl = this.gl;
    gl.deleteFramebuffer(this.framebuffer);
    if (this.depthTexture) gl.deleteTexture(this.depthTexture);
    this.created = false;
    this.updated = false;
  }
  // copy color component to a different texture
  copyColorTo(texture: Texture) {
    // gpu-copy the framebuffer-attached texture to the new texture
    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, this.framebuffer);
    this.gl.bindTexture(this.gl.TEXTURE_2D, texture.texture);
    this.gl.copyTexImage2D(
      this.gl.TEXTURE_2D,
      0,
      this.gl.RGBA,
      0,
      0,
      this.width,
      this.height,
      0
    );
  }
  // create a Texture object from the framebuffer color data texture
  detachColor(): Texture {
    const t = new Texture(this.gl, {
      width: this.width,
      height: this.height,
      magFilter: this.magFilter,
      minFilter: this.minFilter,
      wrapS: this.wrapS,
      wrapT: this.wrapT,
      flip: this.flip,
      premultiply: this.premultiply,
    });
    t.create();
    this.copyColorTo(t);
    return t;
  }
  // save framebuffer data to pixels array
  read(): Uint8Array {
    const gl = this.gl;
    gl.bindFramebuffer(gl.FRAMEBUFFER, this.framebuffer);
    const pixels = new Uint8Array(this.width * this.height * 4); // rgba
    gl.readPixels(
      0,
      0,
      this.width,
      this.height,
      gl.RGBA,
      gl.UNSIGNED_BYTE,
      pixels
    );
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    this.pixels = pixels;
    return pixels;
  }
  // free all gpu-related resources
  free() {
    const gl = this.gl;
    gl.deleteFramebuffer(this.framebuffer);
    gl.deleteTexture(this.texture);
    if (this.depthTexture) gl.deleteTexture(this.depthTexture);
    this.created = false;
    this.updated = false;
  }
}
