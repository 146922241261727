import { ShaderProgram } from "features/draw-call/core/shader-program/shader-program";
import {
  ShaderProgramCollection,
  VertexBufferCollection,
} from "features/draw-call/core/types";
import { GLTFMeshPrimitivePostprocessed } from "@loaders.gl/gltf";
import { primitiveToBuffers } from "features/draw-call/ext/glb-primitive";
import {
  InstancedElements,
  elementsToInstanced,
} from "features/draw-call/core/geometry/instanced-elements";
import { VertexBuffer } from "features/draw-call/core/vertex-buffer";
import { mat4, quat, vec2, vec3 } from "gl-matrix";
import { RenderTask } from "features/draw-call/core/render-task/render-task";
import { OrbitCamera } from "features/draw-call/ext/orbit-camera";
import { QuadGeometry } from "features/draw-call/core/geometry/quad-geometry";
import { rotationTranslationScaleMatrix } from "features/draw-call/core/geometry/array-transforms";
import { vertexShader } from "../shaders/vertex-shader";
import { instancedVertexShader } from "../shaders/instanced-vertex-shader";
import { depthFragmentShader } from "../shaders/depth-fragment-shader";
import { FramebufferTexture } from "features/draw-call/core/framebuffer-texture";
import { shadowFragmentShader } from "../shaders/shadow-fragment-shader";
import { bindOutput } from "features/draw-call/core/output";
import { textureFragmentShader } from "../shaders/texture-fragment-shader";
import { debugVertexShader } from "../shaders/debug-vertex-shader";
import { penumbraFragmentShader } from "../shaders/penumbra-fragment-shader";
import { Texture } from "features/draw-call/core/texture";
import { Box, Cluster } from "features/global-state/app-context";
import { ElementsBufferGeometry } from "features/draw-call/core/geometry/elements-buffer-geometry";
import { perfmon } from "features/perfmon";
import { RenderLoop } from "features/draw-call/core/render-loop";
import {
  BOX_MARGIN,
  CAMERA_PADDING,
  GROUND_OFFSET,
  MAX_PRECALC_FRAMETIME,
  MESH_PADDING,
  SHADOWMAP_SIZE,
  BOX_GROW_TIME,
  MATCAP_MAX,
  SHADOW_INTENSITY,
  SHADOW_COLOR,
  MAX_LIGHT_INTENSITY,
  MIN_LIGHT_INTENSITY,
  mobile,
} from "config";
import { DynamicTextureLoader } from "features/resource-loader/dynamic-texture-loader";
import { isClusterActive } from "./app-state-emerge";
import { AppState } from "features/global-state/state-manager";

export function intColorTo3Floats(out: vec3, color: number): vec3 {
  return vec3.set(
    out,
    ((color >> 16) & 0xff) / 255,
    ((color >> 8) & 0xff) / 255,
    (color & 0xff) / 255
  );
}

export interface FabricAdditionalOptions {
  boxGeometry: ElementsBufferGeometry;
  wallsGeometry: ElementsBufferGeometry;
  camera: OrbitCamera;
  cluster: Cluster;
  programs: ShaderProgramCollection;
  rl: RenderLoop;
  textureCache: DynamicTextureLoader;
  clusterIndex: number;
  sectorIndex: number;
  appState: AppState;
  shadowMapFBT: FramebufferTexture;
}

const _tmp0 = vec2.create();
const _tmp1 = vec2.create();
const _tmp2 = vec3.create();

// calculate the light matrices and position for a cluster
export const getClusterLightData = (cluster: Cluster) => {
  // initialize constants
  const R = cluster.r * 1.5 + MESH_PADDING;
  const lightAngle = -Math.PI / 3;
  const lightDistance = R * 0.75;
  const lightProjection = mat4.create();
  const camR = R + CAMERA_PADDING;
  mat4.ortho(lightProjection, -camR, camR, -camR, camR, 0.1, R * 2);
  const lightView = mat4.create();
  mat4.lookAt(
    lightView,
    [
      lightDistance * Math.sin(lightAngle) + cluster.x,
      camR,
      lightDistance * Math.cos(lightAngle) + cluster.y,
    ],
    [cluster.x, 0, cluster.y],
    [0, 0, 1]
  );
  return { R, lightProjection, lightView, lightDistance, lightAngle };
};

export class ClusterBoxes extends RenderTask {
  instanceBuffers: VertexBufferCollection = {};

  camera: OrbitCamera;
  shadowMapFBT: FramebufferTexture;
  penumbraMap: Texture | null = null;
  lightProjection: mat4;
  lightView: mat4;
  minLightIntensity: number = 0.5;
  maxLightIntensity: number = 0.5;
  shadowIntensity: number = 1;
  matcapMax: number = 1;
  shadowColor: Float32Array = new Float32Array([0.5, 0.5, 0.5]);
  cluster: Cluster;
  creating: boolean = false;
  rl: RenderLoop;
  reschedules = 0;
  startTime: number;
  boxes: { x: number; y: number; l: number; h: number; w: number }[];
  textureCache: DynamicTextureLoader;
  clusterIndex: number;
  sectorIndex: number;
  appState: AppState;

  constructor(gl: WebGL2RenderingContext, options: FabricAdditionalOptions) {
    super(gl);
    this.geometries.sharedBox = options.boxGeometry;
    this.geometries.sharedWalls = options.wallsGeometry;
    this.textureCache = options.textureCache;
    this.clusterIndex = options.clusterIndex;
    this.sectorIndex = options.sectorIndex;
    this.appState = options.appState;

    this.camera = options.camera;
    this.rl = options.rl;

    this.cluster = options.cluster;
    this.startTime = this.rl.time;
    this.boxes = this.cluster.geometry;

    let counter = 0;
    this.shadowMapFBT = options.shadowMapFBT;
    this.shaderPrograms.depth = options.programs.depth;
    this.shaderPrograms.instancedDepth = options.programs.instancedDepth;
    this.shaderPrograms.main = options.programs.shadowRenderer;
    this.shaderPrograms.instanced = options.programs.instancedShadows;
    this.shaderPrograms.penumbra = options.programs.penumbra;
    this.geometries.debug = new QuadGeometry(gl);
    const { R, lightProjection, lightView } = getClusterLightData(this.cluster);
    this.lightProjection = lightProjection;
    this.lightView = lightView;

    // set instance values
    const instanceCount = this.boxes.length;
    const instancePos = new Float32Array(instanceCount * 3);
    const instanceScale = new Float32Array(instanceCount * 3);
    const instanceColor = new Float32Array(instanceCount * 2);
    for (let i = 0; i < instanceCount; i++) {
      const index = i * 3;
      instancePos[index] = this.boxes[i].x;
      instancePos[index + 1] = 0;
      instancePos[index + 2] = this.boxes[i].y;
      instanceScale[index] = this.boxes[i].l - BOX_MARGIN;
      instanceScale[index + 1] = 0; //* || boxes[i].h
      instanceScale[index + 2] = this.boxes[i].w - BOX_MARGIN;
      counter++;
      intColorTo3Floats(_tmp2, ~~((i / instanceCount) * 0xffff));
      const index2 = i * 2;
      instanceColor[index2] = _tmp2[1];
      instanceColor[index2 + 1] = _tmp2[2];
    }
    console.log("Cluster radius: ", R);
    console.log("Objects in this cluster: ", counter);
    const objectCounter = document.getElementById("object-counter");
    if (objectCounter) objectCounter.innerText = `Object count: ${counter}`;
    perfmon.start("instancedGeometries");
    this.instanceBuffers = {
      INSTANCE_POSITION: new VertexBuffer(gl, { data: instancePos, size: 3 }),
      INSTANCE_SCALE: new VertexBuffer(gl, {
        data: instanceScale,
        size: 3,
      }),
      INSTANCE_COLOR: new VertexBuffer(gl, {
        data: instanceColor,
        size: 2,
      }),
    };
    perfmon.stop("instancedGeometries");
    // create instances from a shared geometry
    this.geometries.boxes = elementsToInstanced(
      this.geometries.sharedBox as ElementsBufferGeometry,
      {
        instanceCount,
        instanceBuffers: this.instanceBuffers,
      }
    );
    this.geometries.walls = elementsToInstanced(
      this.geometries.sharedWalls as ElementsBufferGeometry,
      {
        instanceCount,
        instanceBuffers: this.instanceBuffers,
      }
    );
    const ground = new QuadGeometry(gl);
    const groundT = rotationTranslationScaleMatrix(
      mat4.create(),
      [-Math.PI / 2, 0, 0],
      [this.cluster.x, GROUND_OFFSET, this.cluster.y],
      [R + MESH_PADDING, R + MESH_PADDING, 1]
    );

    ground.vertexBuffers.POSITION.applyMat4Position(groundT);
    ground.vertexBuffers.NORMAL.applyMat4Direction(groundT);

    this.geometries.ground = ground;
  }
  create() {
    // if (this.created) return;
    // if current frametime is greater than maximum frametime
    // return early, effectively rescheduling creation for later

    perfmon.start("cluster render task super.create()");
    super.create();
    perfmon.stop("cluster render task super.create()");
    this.created = false;
    if (this.creating) return;

    this.creating = true;
    // setTimeout(() => {
    // render shadow map
    const elapsedTime = this.rl.time - this.startTime;
    // Update the height of the cubes
    const instanceScale = this.instanceBuffers.INSTANCE_SCALE;
    const gl = this.gl;
    gl.bindBuffer(gl.ARRAY_BUFFER, instanceScale.glBuffer);
    const scaleData = new Float32Array(this.boxes.length * 3);
    for (let i = 0; i < this.boxes.length; i++) {
      scaleData[i * 3 + 0] = this.boxes[i].l - BOX_MARGIN;
      scaleData[i * 3 + 1] = this.boxes[i].h;
      scaleData[i * 3 + 2] = this.boxes[i].w - BOX_MARGIN;
    }
    gl.bufferSubData(gl.ARRAY_BUFFER, 0, scaleData);

    // Render shadow map
    perfmon.start("shadowmaps");
    bindOutput(this.gl, this.shadowMapFBT);
    gl.enable(gl.DEPTH_TEST);
    gl.disable(gl.BLEND);
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
    gl.depthFunc(gl.LESS);
    this.geometries.ground.drawCall(this.shaderPrograms.depth, {
      projectionMatrix: this.lightProjection,
      viewMatrix: this.lightView,
      lightProjectionMatrix: this.lightProjection,
      lightViewMatrix: this.lightView,
    });
    this.geometries.boxes.drawCall(this.shaderPrograms.instancedDepth, {
      lightProjectionMatrix: this.lightProjection,
      lightViewMatrix: this.lightView,
      projectionMatrix: this.lightProjection,
      viewMatrix: this.lightView,
    });
    this.penumbraMap = this.shadowMapFBT.detachColor();
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
    this.geometries.debug.drawCall(this.shaderPrograms.penumbra, {
      shadowMap: this.penumbraMap,
    });
    this.shadowMapFBT.copyColorTo(this.penumbraMap);
    this.bindOutput();
    gl.enable(gl.BLEND);
    perfmon.stop("shadowmaps");

    // create the final penumbra map
    /*
      this.penumbraMap = new Texture(gl, {
        width: SHADOWMAP_SIZE,
        height: SHADOWMAP_SIZE,
        pixels: this.penumbraMapFBT.read(),
      });*/

    // free framebuffer resources
    // this.shadowMapFBT.free();
    // this.penumbraMapFBT.freeFramebuffer();
    this.created = true;
    this.creating = false;
    perfmon.stop("shadowmaps");
    //this.textureCache.textures.set(
    //  `cluster-${this.cluster.c}-shadows`,
    //  this.penumbraMap
    //);
    // }, 0);
  }
  render(): void {
    super.render();
    if (!this.created) return;

    const elapsedTime = this.rl.time - this.startTime;
    const animationProgress = Math.min(1, elapsedTime / BOX_GROW_TIME);
    // Update the height of the cubes
    const instanceScale = this.instanceBuffers.INSTANCE_SCALE;
    const gl = this.gl;
    gl.bindBuffer(gl.ARRAY_BUFFER, instanceScale.glBuffer);
    const scaleData = new Float32Array(this.boxes.length * 3);
    for (let i = 0; i < this.boxes.length; i++) {
      scaleData[i * 3 + 0] = this.boxes[i].l - BOX_MARGIN;
      scaleData[i * 3 + 1] = this.boxes[i].h * animationProgress;
      scaleData[i * 3 + 2] = this.boxes[i].w - BOX_MARGIN;
    }
    gl.bufferSubData(gl.ARRAY_BUFFER, 0, scaleData);

    const uniforms = {
      projectionMatrix: this.camera.projectionMatrix,
      viewMatrix: this.camera.viewMatrix,
      lightProjectionMatrix: this.lightProjection,
      lightViewMatrix: this.lightView,
      minLightIntensity: MIN_LIGHT_INTENSITY,
      maxLightIntensity: MAX_LIGHT_INTENSITY,
      shadowColor: SHADOW_COLOR,
      shadowIntensity: SHADOW_INTENSITY * (1 - this.camera.transitionValue),
      matcapMax: MATCAP_MAX,
      circle: false,
      penumbraMap: this.penumbraMap!,
    };
    const groundUniforms = {
      projectionMatrix: this.camera.projectionMatrix,
      viewMatrix: this.camera.viewMatrix,
      lightProjectionMatrix: this.lightProjection,
      lightViewMatrix: this.lightView,
      minLightIntensity: MIN_LIGHT_INTENSITY,
      maxLightIntensity: MAX_LIGHT_INTENSITY,
      shadowColor: SHADOW_COLOR,
      shadowIntensity: SHADOW_INTENSITY * (1 - this.camera.transitionValue),
      matcapMax: MATCAP_MAX,
      circle: true,
      penumbraMap: this.penumbraMap!,
    };

    this.gl.depthMask(false);
    this.geometries.ground.drawCall(this.shaderPrograms.main, groundUniforms);
    this.gl.depthMask(true);
    if (
      isClusterActive(this.appState.state, this.clusterIndex, this.sectorIndex)
    ) {
      this.geometries.walls.drawCall(this.shaderPrograms.instanced, uniforms);
    } else {
      this.geometries.boxes.drawCall(this.shaderPrograms.instanced, uniforms);
    }
    // debug renderer
    // this.geometries.debug.drawShader(this.shaderPrograms.debug);
    // this.geometries.debug.drawShader(this.shaderPrograms.penumbra);
  }
}
